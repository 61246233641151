import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Container,
  Layout,
  Section,
  Main,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"
import { SecondaryPagesHero, Seo } from "../../../components/elements/"

const Team = ({ data, pageContext }) => {
  const page = data.datoCmsCancerTeam
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Seo
        title={page.seo.title || page.title}
        description={page.seo.description || " "}
        image={page.seo.image  || " "}
      />

      <SecondaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </SecondaryPagesHero>

      <Section>
        <Container>
          <Main>
            <Flex flexWrap="wrap" justifyContent="center">
              <Box
                sx={{ width: ["full", "full", 3 / 4] }}
                dangerouslySetInnerHTML={{
                  __html: page.contentNode.childMarkdownRemark.html,
                }}
              />
            </Flex>
          </Main>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query teamPageQuery($slug: String!) {
    datoCmsCancerTeam(slug: { eq: $slug }) {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      id
      title
      slug
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { ar: "1600:720", fit: "crop", crop: "focalpoint" }
        )
      }
      meta {
        status
      }
      seo {
        description
        title
        image {
          url
        }
      }
    }
  }
`

export default Team
